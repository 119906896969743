import React from 'react';
import { useInView } from 'react-intersection-observer';

function Newsletter() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  return (
    <section className={`section bg-black newsletter ${inView ? 'in-view' : ''}`} ref={ref} id="signup">
      <div className="site-wide">
        <div className="flex">
          <div className="col copy">
            <h3>Join the flock! Sign up to our newsletter:</h3>
          </div>

          <div className="col form">
            <form
              className="js-cm-form"
              id="subForm"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="2BE4EF332AA2E32596E38B640E905619BAFD8A7FC14E32956E6C6B874765F8D2AA755A3D6E83D13EA974040E6CB4B3C8613E23841C13BB9BFCC64630E27E68F0"
            >
              <input
                type="text"
                placeholder="First name"
                aria-label="First Name"
                id="fieldName"
                maxLength="200"
                name="cm-name"
              />
              <input
                type="email"
                placeholder="Your email address"
                autoComplete="Email"
                aria-label="Email"
                className="js-cm-email-input qa-input-email"
                id="fieldEmail"
                maxLength="200"
                name="cm-jtlhxr-jtlhxr"
                required
              />
              <label htmlFor="cm-privacy-consent">
                <input
                  type="checkbox"
                  aria-required
                  id="cm-privacy-consent"
                  name="cm-privacy-consent"
                  required
                />
                I agree to receive latest information, news and updates.
              </label>
              <input
                id="cm-privacy-consent-hidden"
                name="cm-privacy-consent-hidden"
                type="hidden"
                value="true"
              />
              <button type="submit" className="btn btn--white">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
