import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import AnimatedText from '../global/animated-text';
import Container from '../global/container';

function InsightPost(props) {
  const { _rawHeroText, _rawContent, team, title, heroMedia, publishedAt } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  useEffect(() => {
    window.addEventListener('load', (event) => {
      window.instgrm.Embeds.process();
    });
  });

  return (
    <Container>
      <article className="insight">
        <div className={`hero hero--insight bg-black ${inView ? 'in-view' : ''}`} ref={ref}>
          {heroMedia.image && heroMedia.image.asset && (
            <img
              src={imageUrlFor(buildImageObj(heroMedia.image))
                .url()}
              alt={heroMedia.alt}
            />
          )}
          {heroMedia.video && heroMedia.video.asset && (
            <video className="hero__media" autoPlay loop muted playsInline>
              <source src={heroMedia.video.asset.url} type="video/mp4" />
            </video>
          )}
          <div className="site-wide">
            <div className="grid-container grid-container--half">
              <div className="grid-column">
                <h1>{title}</h1>
                <div className="insight__meta">
                  <div className="insight__meta-date">{format(publishedAt, 'MMMM YYYY')}</div>
                  {team &&
                    team.map((member, index) => (
                      <div className="insight__meta-author" key={index}>
                        {member.team.name}
                      </div>
                    ))}
                </div>
                {_rawHeroText && <AnimatedText blocks={_rawHeroText} />}
              </div>
            </div>
          </div>
        </div>

        <div className="site-wide insight--main">
          {_rawContent &&
            _rawContent.map((rC, index) => (
              <div
                className={
                  rC.column2
                    ? 'insight__section grid-container grid-container--half'
                    : 'insight__section'
                }
                key={index}
              >
                <div className="grid-column">
                  {rC.column1 &&
                    rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                </div>
                <div className="grid-column">
                  {rC.column2 &&
                    rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                </div>
              </div>
            ))}
        </div>
      </article>
    </Container>
  );
}

InsightPost.propTypes = {
  _rawHeroText: PropTypes.array,
  _rawContent: PropTypes.array,
  team: PropTypes.array,
  title: PropTypes.string,
  heroMedia: PropTypes.object,
  publishedAt: PropTypes.string
};

export default InsightPost;
