import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../components/global/container';
import Header from '../components/global/header';
import Newsletter from '../components/global/newsletter';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import Insight from '../components/insights/insight';
import InsightSmallCardList from '../components/insights/insight-small-card-list';
import Layout from '../containers/layout';
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
  toPlainText
} from '../lib/helpers';
import Infographic from '../components/insights/infographic';

export const query = graphql`
  query InsightDetailTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawInsightscopy
      _rawTeamcopy
    }
    insights: allSanityInsight(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
        }
      }
    }
    post: sanityInsight(id: { eq: $id }) {
      # categories {
      #   _id
      #   title
      # }
      id
      slug {
        current
      }
      title
      publishedAt
      team {
        _key
        team {
          name
        }
      }
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      heroMedia {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            _id
            url
          }
        }
        alt
      }
      _rawHeroText(resolveReferences: { maxDepth: 10 })
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const InsightDetailTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const site = (data || {}).site;
  const insights = (data || {}).insights
    ? mapEdgesToNodes(data.insights)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <>
      <Header headerStyle="mix" />
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post.seo && (
          <SEO
          title={post.seo.title !== null ? post.seo.title : false}
          description={post.seo.description !== null ? post.seo.description : false}
          keywords={post.seo.keywords !== null ? post.seo.keywords : false}
          image={post.seo.image !== null ? post.seo.image : false}
        />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <Insight {...post} />}

        {post.slug.current == 'what-you-need-to-know-before-you-start-a-new-website-design-project' && 
          <Infographic />
        }

        <Newsletter />
        {insights && <InsightSmallCardList nodes={insights} cta copy={site} />}
      </Layout>
    </>
  );
};

InsightDetailTemplate.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default InsightDetailTemplate;
