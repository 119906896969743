import React from 'react';
import InfographicDesk from '../../static/infographic/infographic.svg';
import InfographicMob from '../../static/infographic/infographic-mob.svg';

function Infographic() {
  return (
    <div className="site-wide web-infographic">
      
      <div className='infog--main'>
        <img src={InfographicDesk} alt="Lifecycle of a website project" />
      </div>

      <div className='infog--mob'>
        <img src={InfographicMob} alt="Lifecycle of a website project" />
      </div>

    </div>
  );
}

export default Infographic;
